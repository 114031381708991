hbspt.forms.create({
  region: 'eu1',
  portalId: '143894765',
  formId: '8ef3899b-4eb7-4f0a-8f85-43a9e4addef6',
  cssClass: 'done-input-group done-input-group-newsletter',
  target: '#hubspotFormContainer',
  submitButtonClass: 'btn btn-primary',
  onFormReady: function (form) {
    const emailInput = form.querySelector('input[name="email"]');
    newsletter.setHubspotEmailInput(emailInput);
    if (emailInput) {
      emailInput.value = document.getElementById('emailNewsletter').value;
    }

    const inputs = form.querySelector('input[type="email"]');
    inputs.classList.add('done-input-group-input');
    inputs.classList.add('done-input-group-input-newsletter');

    form.addEventListener('submit', function (event) {
      event.preventDefault();
      form.submit();
    });
  }
});
